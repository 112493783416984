import { PropTypes } from "prop-types";
import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, DialogActions, DialogContent, DialogTitle, Stack } from "@mui/material";
import { LoadingButton } from '@mui/lab';
import FormProvider, { RHFTextField } from 'src/components/hook-form';
import { DialogAnimate } from "src/components/animate";
import { useDispatch, useSelector } from "src/redux/store";
import { addUserFeedback } from "src/redux/slices/user";
import { clearAction } from "src/redux/slices/general";
import Notification from "src/components/Notification";

FeedbackForm.propTypes = {
    isOpen: PropTypes.func,
    onClose: PropTypes.func,
};

export default function FeedbackForm({ isOpen, onClose }) {
    const dispatch = useDispatch();
    const { addedFeedback, error } = useSelector((data) => data.general);
    const [notification, setNotification] = useState({ open: false, msg: '', type: 'success', });

    const FeedbackSchema = Yup.object().shape({
        email: Yup.string().required('Email Address is required').email("Email Address must be valid"),
        fullName: Yup.string().required('Feedback is required'),
        subject: Yup.string().required('Feedback is required'),
        feedback: Yup.string().required('Feedback is required'),
    });

    const defaultValues = {
        email: "",
        fullName: "",
        subject: "",
        feedback: "",
    }

    const methods = useForm({
        resolver: yupResolver(FeedbackSchema),
        defaultValues,
    });

    const {
        reset,
        handleSubmit,
        formState: { errors, isSubmitting },
    } = methods;

    const onSubmit = async (data) => {
        try {
            await new Promise((resolve) => setTimeout(resolve, 500));
            console.log(data) // API 
            dispatch(addUserFeedback(data.subject, data.feedback, data.fullName, data.email))
        } catch (Error) {
            console.error(Error);
        }
    };

    useEffect(() => {
        if (addedFeedback) {
            setNotification({ open: true, msg: 'Feedback Submitted Successfully', type: 'success', });
            dispatch(clearAction());
            reset();
            onClose();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addedFeedback])

    return (
        <>
            <DialogAnimate open={isOpen} onClose={onClose}>
                <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                    <DialogTitle id="alert-dialog-title" sx={{ textAlign: "center" }}>Your FEEDBACK is important to us!! </DialogTitle>
                    <DialogContent>
                        <Stack spacing={1}>
                            <RHFTextField name="email" label="Email Address" />
                            <RHFTextField name="fullName" label="Full Name" />
                            <RHFTextField name="subject" label="Subject" />
                            <RHFTextField name="feedback" label="Feedbacks" multiline rows={5} />
                        </Stack>
                    </DialogContent>
                    <DialogActions sx={{ justifyContent: "center" }}>
                        <Button variant="outlined" onClick={onClose} color="inherit">
                            Cancel
                        </Button>

                        <LoadingButton autoFocus type="submit" variant="contained" loading={isSubmitting}>
                            Submit
                        </LoadingButton>
                    </DialogActions>
                </FormProvider>
            </DialogAnimate>
            <Notification notification={notification} setNotification={setNotification} />
        </>
    )
};